var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dc43357847daaa8b60258416c043b3bb724a0101"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NODE_ENV === 'production'
    ? process.env.NEXT_PUBLIC_SENTRY_DSN
    : '',
  /**
   * We recommend adjusting this value in production, or using tracesSampler
   * for finer control 
   */
  tracesSampleRate: 0.0,
  /**
   * ...
   * Note: if you want to override the automatic release value, do not set a
   * `release` value here - use the environment variable `SENTRY_RELEASE`, so
   * that it will also get attached to your source maps
   */
});